import React from 'react';

import DOMPurify from 'isomorphic-dompurify';
import Head from 'next/head';
import { useRouter } from 'next/router';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import { getFullRoute } from '@services/http/Route';

type HeadProps = {
    showLandBot?: boolean;
    title?: string;
    description?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogUrl?: string;
    ogImage?: string;
    ogImageWidth?: string;
    ogImageHeight?: string;
    ogSiteName?: string;
    isPotPage?: boolean;
    metaTwitterCard?: string;
};

const CustomHead = ({
    title,
    description,
    ogTitle,
    ogDescription,
    ogUrl,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    ogSiteName,
    isPotPage,
    showLandBot,
    metaTwitterCard,
}: HeadProps) => {
    const { t } = useTranslateLpc(['common'], { useSuspense: false });
    const router = useRouter();

    const metaTitle = title || t('meta-tag.default-title');
    const metaDescription = description || t('meta-tag.default-description');
    const metaNoIndex = t('meta-tag.default-no-index');

    const metaOgTitle = ogTitle || t('meta-tag.default-title');
    const metaOgDescription = ogDescription || t('meta-tag.default-description');
    const metaOgSiteName = ogSiteName || t('meta-tag.default-site-name');
    const metaOgUrl = ogUrl || router.pathname;
    const metaOgImage = ogImage || getFullRoute('/front-static/images/opengraph-lpc.png');
    const metaOgImageWidth = ogImageWidth || '1200';
    const metaOgImageHeight = ogImageHeight || '630';
    return (
        <>
            <Head>
                <meta charSet={'utf-8'} />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                    name="viewport"
                />

                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                {isPotPage && <meta name="robots" content={metaNoIndex} />}

                <meta property="og:type" content={'website'} />
                <meta property="og:title" content={metaOgTitle} />
                <meta property="og:description" content={metaOgDescription} />
                <meta property="og:site_name" content={metaOgSiteName} />
                <meta property="og:url" content={metaOgUrl} />
                <meta property="og:image" content={metaOgImage} />
                <meta property="og:image:width" content={metaOgImageWidth} />
                <meta property="og:image:height" content={metaOgImageHeight} />
                <link rel="image_src" href={metaOgImage} />

                <meta name="twitter:card" content={metaTwitterCard || ''} />
                <meta name="twitter:title" content={metaOgTitle} />
                <meta name="twitter:description" content={metaOgDescription} />
                <meta name="twitter:image" content={metaOgImage} />
                <meta property="twitter:image:width" content={metaOgImageWidth} />
                <meta property="twitter:image:height" content={metaOgImageHeight} />

                <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />
                {showLandBot && (
                    <script
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(`window.addEventListener('mouseover', initLandbot, { once: true });
                            window.addEventListener('touchstart', initLandbot, { once: true });
                            var myLandbot;
                            function initLandbot() {
                              if (!myLandbot) {
                                var s = document.createElement('script');s.type = 'text/javascript';s.async = true;
                                s.addEventListener('load', function() {
                                  var myLandbot = new Landbot.Livechat({
                                    configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1726142-H5E7IZZQTRJD2FBE/index.json',
                                  });
                                });
                                s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
                                var x = document.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                              }
                            }`),
                        }}
                    ></script>
                )}
            </Head>
        </>
    );
};

export default CustomHead;
