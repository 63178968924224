import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import css from './HomeCustomBanner.module.scss';

type BannerProps = {
    onHide: () => void;
    actionUrl: string;
    img: string;
    hideBanner: boolean;
    openNewWindow?: boolean;
    linkTitleBN?: string;
};

const HomeCustomBanner = ({ onHide, actionUrl, hideBanner, img, openNewWindow, linkTitleBN }: BannerProps) => {
    const router = useRouter();

    if (hideBanner) {
        return null;
    }

    return (
        <>
            <img
                width={1000}
                height={100}
                onClick={() => {
                    if (openNewWindow) {
                        window.open(actionUrl, '_blank');
                    } else {
                        router.push(actionUrl);
                    }
                }}
                src={`${img}`}
                className={css.contest__banner}
                alt={linkTitleBN || 'home-event-banner'}
            />
            <Image
                width={25}
                height={25}
                onClick={(event) => {
                    onHide();
                    event.stopPropagation();
                }}
                className={css.contest__banner__close}
                src="/front-static/icons/navigation/close-white.svg"
                alt="close"
            />
        </>
    );
};

export default HomeCustomBanner;
